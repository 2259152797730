const Magnify = {

    timeResize : false,

    init : function(){

        const items = document.querySelectorAll( '[data-amplify="true"]' );
        if( items.length ){
            items.forEach( image => {
                Magnify.configure( image );
            });
        }

        window.addEventListener( 'resize', () => {
            if( Magnify.timeResize ) clearTimeout( Magnify.timeResize );
            Magnify.timeResize = setTimeout( function(){
                Magnify.bindResize( items );
            }, 100 );
        });
    
    },

    bindResize : function( items ){
        items.forEach( image => {
            Magnify.setDimensions( image.Magnify );            
        });
    },

    configure : function( image ){

        if( image.dataset.Magnify !== undefined ) return true;

        let data = {
            image : image,
            wrapper : image.parentElement,
            header : document.querySelector( 'body > div > header' ),
            glass : document.createElement( 'DIV' ),
            minWidth : image.dataset.minWidth === undefined ? false : image.dataset.minWidth,
            responsive : false,
            cta : document.querySelector( '.hero a.button' )
        };

        // Min width
        if( data.minWidth ){
            const vpWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );
            if( vpWidth < vpWidth ){
                return true;
            }
        }

        // Insert amplifyer
        data.glass.setAttribute( 'class', 'img-magnifier-glass' );
        data.wrapper.insertBefore( data.glass, data.image );

        // Responsive
        if( data.wrapper.tagName === 'PICTURE' && ( sources = data.wrapper.querySelectorAll( 'source' ) ).length ){
        
            data.responsive = [];
            sources.forEach( source => {

                const sInfo = source.media.replace( '(', '' ).replace( ')', '' ).split( ':' );

                if( sInfo[ 0 ] === 'min-width' || sInfo[ 0 ] === 'max-width' ){
                    data.responsive.push({
                        type : sInfo.shift( sInfo ).replace( '-width', '' ),
                        size : parseInt( sInfo.pop( sInfo ) ),
                        amplifyURL : source.dataset.amplified !== undefined ? source.dataset.amplified : false,
                        zoom : source.dataset.zoom !== undefined ? source.dataset.zoom : false
                    });
                }

            });
            
        }

        Magnify.setDimensions( data );

        // Binders
        data.glass.addEventListener( 'mousemove', event => { Magnify.bindMove( data, event ) });
        data.glass.addEventListener( 'touchmove', event => { Magnify.bindMove( data, event ) });
        data.image.addEventListener( 'mousemove', event => { Magnify.bindMove( data, event ) });
        data.image.addEventListener( 'touchmove', event => { Magnify.bindMove( data, event ) });

        // Keep data
        data.image.Magnify = data;

        // Bind hide glass
        data.cta.addEventListener( 'mouseover', () => {
            data.glass.classList.add( 'hide' );
        });
        data.cta.addEventListener( 'mouseout', () => {
            data.glass.classList.remove( 'hide' );
        });

    },

    setDimensions : function( data ){

        // Defaults
        data.imageWidth = data.image.width;
        data.imageHeight = data.image.height;
        data.glassW = data.glass.offsetWidth / 2;
        data.glassH = data.glass.offsetHeight / 2;
        data.limitY = data.image.offsetTop + data.image.offsetHeight + data.header.offsetHeight;
        data.zoom = data.image.dataset.zoom === undefined ? 3 : data.image.dataset.zoom;
        data.amplifyURL = data.image.dataset.amplified === undefined ? data.image.src : data.image.dataset.amplified;

        // Search breakpoint, if responsive
        let 
            breakuse = false,
            backgroundImage = data.amplifyURL,
            zoom = data.zoom
        ;
        if( data.responsive ){

            var vpWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );

            data.responsive.forEach( breakpoint => {

                if( 
                    ( breakpoint.type === 'min' && vpWidth >= breakpoint.size )
                    ||
                    ( breakpoint.type === 'max' && vpWidth <= breakpoint.size )
                ){
                    breakuse = breakpoint;
                }

            });

        }
        if( breakuse ){
            if( breakuse.amplifyURL ){
                backgroundImage = breakuse.amplifyURL;
            }
            if( breakuse.zoom ){
                zoom = breakuse.zoom;
            }
        }

        data.glass.style.backgroundImage = "url('" + backgroundImage + "')";
        data.glass.style.backgroundSize = ( data.imageWidth * zoom ) + "px " + ( data.imageHeight * zoom ) + "px";
        
    },

    bindMove : function( data, event ){

        event.preventDefault();

        if( event.pageY <= data.limitY ){
            Magnify.move( data, event );
        }

    },

    move : function( data, event ){
        
        var 
            pos = Magnify.getCursorPosition( data, event ), 
            posX = pos.x,
            posY = pos.y
        ;

        // Prevent the magnifier glass from being positioned outside the image:
        if( posX > data.imageWidth - ( data.glassW / data.zoom ) ){ 
            posX = data.imageHeight - ( data.glassW / data.zoom );
        }
        if( posX < data.glassW / data.zoom ){
            posX = data.glassW / data.zoom;
        }
        if( posY > data.imageHeight - ( data.glassH / data.zoom ) ){
            posY = data.imageHeight - ( data.glassH / data.zoom );
        }
        if( posY < data.glassH / data.zoom ){
            posY = data.glassH / data.zoom;
        }
        
        // Set the position of the magnifier glass:
        data.glass.style.left = ( posX - data.glassW ) + "px";
        data.glass.style.top = ( posY - data.glassH ) + "px";

        // Display what the magnifier glass "sees": */
        data.glass.style.backgroundPosition = "-" + ( ( posX * data.zoom ) - data.glassW ) + "px -" + ( ( posY * data.zoom ) - data.glassH ) + "px";
        
    },

    getCursorPosition : function( data, event ){

        var 
            a, 
            x = 0, 
            y = 0
        ;

        e = event || window.event;
        a = data.image.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;

        return { x : x, y : y };
    
    }

};


document.addEventListener( 'DOMContentLoaded', function(){
    Magnify.init();
});