(function($){

    var _window;

    var IDEC = {

        $ : null,
        $header  : null,
        $menu : null,
        $video : null,
        lastScrolTop : false,
        timeResize : false,
        currentMouseY : -1,
        lastGadget : false,
        modalNews = false,

        init : function(){

            _window = $( window );

            IDEC.$ = $( '#rotulo-lnd' );
            if( !IDEC.$.length ) return true;

            IDEC.$header = IDEC.$.children( 'nav' ).first();
            IDEC.$menu = $( '#menu', IDEC.$ ).first();

            // Vídeo
            IDEC.$video = $( '#video-wrapper' ).first();

            //  Binders
            IDEC.bindLinks();
            IDEC.bindHeader();
            IDEC.bindHero();
            IDEC.bindSlick();
            IDEC.bindResize();
            IDEC.bindNews();
            IDEC.bindFooter();

            if( IDEC.isMobile() ){
                IDEC.mobileHeights();
            }

            $(window).resize(function() {
                IDEC.bindResize();
            }).resize();
            
        },

        bindLinks(){
            document.querySelectorAll( 'a' ).forEach( link => {
                if( link.href.indexOf( 'idec.org' ) >= 0 ){
                    link.href = link.href + '?utm_medium=site&utm_source=campanha-rotulagem&utm_campaign=campanha-rotulagem&utm_content=lp-deolho-nosrotulos&utm_term=' + link.dataset.term;
                }
            });
        },

        bindHero : function(){

            const scrollDown = document.querySelector( '[data-action="scroll-down"]' );
            scrollDown.addEventListener( 'click', event => {
                event.preventDefault();
                const newPosY = document.querySelector( '.hero' ).offsetHeight;
                window.scrollTo( 0, newPosY );
            });

        },

        bindFooter : function(){

            const 
                footer = document.querySelector( 'body > div > footer' ),
                limitTop = document.querySelector( 'body > div > header' ).offsetHeight
            ;

            // Set button floating
            footer.classList.add( 'fix-button-news' );

            // Bind scroll
            window.addEventListener( 'scroll', event => {

                const scrollPosition = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
                if( scrollPosition > limitTop ){
                    footer.classList.add( 'show-button-news' );
                }
                else {
                    footer.classList.remove( 'show-button-news' );
                }

            });

        },

        bindNews : function(){

            const buttons = document.querySelectorAll( '[data-action="open-news-form"]' );
            if( buttons.length ){

                IDEC.modalNews = document.querySelector( '#modal-newsletter' );
                IDEC.modalNewsIframe = IDEC.modalNews.querySelector( 'iframe' );

                buttons.forEach( button => {
                    button.addEventListener( 'click', event => {
                        IDEC.openNews();
                    });  
                });

                // Close on clickout
                IDEC.modalNews.addEventListener( 'click', event => {
                    if( event.target.id === 'modal-newsletter-inner' || event.target.id === 'modal-newsletter' ){
                        IDEC.closeNews();
                    }
                });

                // Close button
                const closeButton = document.querySelector( '[data-action="close-news-form"]' );
                closeButton.addEventListener( 'click', event => {
                    IDEC.closeNews();
                });
            
            }

        },

        openNews : function(){

            // Load page
            if( IDEC.modalNewsIframe.src === 'about:blank' ){
                IDEC.modalNewsIframe.src = IDEC.modalNewsIframe.dataset.address;
            }
        
            // Show modal
            IDEC.modalNews.classList.add( 'opened' );
        
        },
        
        closeNews : function(){
            IDEC.modalNews.classList.remove( 'opened' );
        },

        bindResize : function(){
            if( IDEC.timeResize ) clearTimeout( IDEC.timeResize );
            setTimeout(function(){
                IDEC.checkVideo();
            }, 50 );
        },

        checkVideo : function(){

            var vWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );

            var gadget;
            if( vWidth >= 825 ){
                gadget = 'desktop';
            }
            else if( vWidth > 413 ){
                gadget = 'tablet';
            }
            else {
                gadget = 'mobile';
            }

            if( gadget !== IDEC.lastGadget ){

                var _src = IDEC.$video.data( 'source-' + gadget );

                IDEC.$video.html('\
                    <video preload="auto" muted loop autoplay playsinline>\
                        <source type="video/webm" src="'+ _src +'.webm">\
                        <source type="video/mp4" src="'+ _src +'.mp4">\
                        Your browser does not support the video tag.\
                    </video>\
                ');

                IDEC.lastGadget = gadget;

            }
            
        },

        mobileHeights : function(){
            var vHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },

        scrollHeight : function(){
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        },

        // Binding header
        bindHeader : function(){
            // Close menu
            $( 'button', IDEC.$menu ).on( 'click', function(){
                IDEC.closeMenu();
            });
            $( '#menu a', IDEC.$menu ).on( 'click', function(){
                IDEC.closeMenu();
            });
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( IDEC.$.hasClass( 'menu-opened' ) ){
                IDEC.closeMenu();
            }
            else {
                IDEC.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            IDEC.$menuToggle.addClass( 'opened' );
            IDEC.$.addClass( 'menu-opened' );
        },

        // Close menu mobile
        closeMenu : function(){
            IDEC.$menuToggle.removeClass( 'opened' );
            IDEC.$.removeClass( 'menu-opened' );
        },

        bindSlick : function(){

            const 
                vpWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 ),
                $sliders = $( 'div.list-images' )
            ;
            if( vpWidth <= 480 && $sliders.length ){
                $sliders.slick({
                    dots: true,
                    arrows: false,
                    infinite: false
                });
            }
        
        },

        scrollTo : function( selector ){

            var $target = typeof( selector ) === 'object' ? selector : $( selector );            
            if( !$target.length ) return;

            var newY = $target.offset().top;
            var actualY = _window.scrollTop();
            var distance = actualY - newY;
            if( distance < 0 ) distance *= -1;

            var time = ( 600 - ( distance * 0.1 ) );
            if( time < 600 ) time = 600;
            
            var offset = $target.data( 'offset' );
            if( offset ){

                try {

                    var 
                        _json = JSON.parse( offset.split( "'" ).join( '"' ) )
                        _offset = false,
                        _vWidth = IDEC.vWidth()
                    ;

                    $.each( _json.breaks, function( _break ){
                        if( _vWidth <= _break ){
                            _offset = parseInt( this );
                            return false;
                        }
                    });

                    offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

                }
                catch( e ){
                    var _offset = parseInt( offset );
                    offset = _offset > 0 ? _offset : 0;
                }

            }
            else {
                offset = 0;
            }

            $( 'html, body' ).animate({
                scrollTop: ( newY - ( offset + 30 ) )
            }, time );      

        },

        vWidth : function vWidth(){
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        },

        isMobile : function(){
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        }

    };
    window.addEventListener( 'DOMContentLoaded', () => {
        IDEC.init();
    });

})(jQuery);
